import React from 'react';
import Img from 'gatsby-image';

const Post = ({ post }) => {
  return (
    <article className='post-item'>
      <div className='post-item__video'>
        {post.frontmatter.video ? (
          <iframe
            src={post.frontmatter.video.url}
            title={post.frontmatter.video.title}
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen
          />
        ) : (
          <>
            {post.frontmatter?.thumbnail ? (
              <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
            ) : null}
          </>
        )}
      </div>
      <div className='post-item__content mt-5 lg:mt-0'>
        <h5 className='post-item__title'>{post.frontmatter.title}</h5>

        <div
          dangerouslySetInnerHTML={{ __html: post.html }}
          className='post-item__body markdown-content'
        ></div>
      </div>
    </article>
  );
};
export default Post;
