import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '@/components/Layout';
import ReactMarkdown from 'react-markdown';
import Posts from '@/components/Blog/Posts';
import Helmet from 'react-helmet';
import SEO from '@/helpers/SEOHelper.js';

export const ResourcesPageTemplate = ({ data }) => {
  const seo = data.seo;
  return (
    <>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>

      <div className='min-h-screen '>
        <section className='hero'>
          <div className='container'>
            <ReactMarkdown
              source={data.hero}
              className='markdown-content text-center'
            />
          </div>
        </section>
        <Posts />
        <section className='pb-13 '>
          <div className='container'>
            <div className='line mb-13' />
            <div className='flex flex-col lg:flex-row lg:justify-between items-center'>
              <ReactMarkdown
                source={data.channel}
                className='markdown-content text-center lg:text-left text-black max-w-2xl'
              />
              <a
                href='https://www.youtube.com/@SEMplicityInc'
                className='btn btn--red btn-m mt-10 lg:mt-0'
              >
                Visit our direct channel
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const ResourcesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout hideNav={true}>
      <ResourcesPageTemplate data={frontmatter} />
    </Layout>
  );
};

ResourcesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const pageQuery = graphql`
  query ResourcesPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "resources" } }) {
      frontmatter {
        title
        hero
        channel
        footer
        seo {
          description
          title
        }
      }
    }
  }
`;

export default ResourcesPage;
