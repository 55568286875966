import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Post from '@/components/Blog/Post';

const NewestPosts = ({ data, count }) => {
  const { edges: posts } = data.allMarkdownRemark;
  console.log(posts);
  return (
    <section className='posts-feed pb-20'>
      <div className='container'>
        <div className='flex flex-col gap-10 lg:gap-24'>
          {posts &&
            posts.map(({ node: post }) => (
              <Post key={post.id} post={post}></Post>
            ))}
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query NewestPostsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "article" } } }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                video {
                  url
                  title
                }
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewestPosts data={data} count={count} />}
  />
);
